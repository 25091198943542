<template>
	<div>
		<Header v-if="globalData" :data="globalData"/>
		<main>
			<div class="component">
                <div class="grid grid-cols-12">
                    <div class="col-span-12">
                        <h1 class="headline-maxi py-20">404 page not found</h1>
                    </div>
                </div>
            </div>
		</main>
		<Footer  v-if="globalData" :data="globalData"/>
	</div>
</template>

<script setup>
import { gloablQuery } from '@/api/queries'
import { ref } from 'vue';
const config = useRuntimeConfig();
const route = useRoute();

const shield = ref(false)
const setAuth = () => {
	shield.value = true
}

if (process.client) {
	const cookieValue = document.cookie
		.split("; ")
		.find((row) => row.startsWith("shield="))
		?.split("=")[1];

	if (cookieValue) {
		shield.value = true
	}
}

const globalData = ref(null)

const query = gloablQuery()
const token = route.query.token;

const getData = async () => {
	const { data: gql } = await useFetch(config.public.API, {
		params: { query, token },
	});

	if (gql.value) {
		globalData.value = gql.value.data.globalSets;
	}
}

getData()


// const mainStore = useMainStore()
// const route = useRoute()
// const config = useRuntimeConfig()
// const { $urlFor } = useNuxtApp()
// const ogImageUrl = mainStore.siteOptions?.seo?.ogImage
// 	? $urlFor(mainStore.siteOptions?.seo?.ogImage).width(1200).url()
// 	: ''


// useHead({
// 	titleTemplate: (title) =>
// 		title
// 			? `${mainStore.siteOptions?.name} - ${title}`
// 			: mainStore.siteOptions?.name,
// 	htmlAttrs: {
// 		lang: mainStore.siteOptions?.language ?? 'en',
// 	},
// 	meta: [
// 		{
// 			property: 'og:locale',
// 			content: mainStore.siteOptions?.language ?? 'en',
// 		},
// 		{
// 			property: 'og:title',
// 			content: mainStore.siteOptions?.name,
// 		},
// 		{
// 			property: 'og:site_name',
// 			content: mainStore.siteOptions?.name,
// 		},
// 		{
// 			property: 'og:url',
// 			content: `${config.public.BASE_URL}${route.fullPath}`,
// 		},
// 		{
// 			name: 'description',
// 			content: mainStore.siteOptions?.seo?.metaDescription,
// 		},
// 		{
// 			property: 'og:description',
// 			content: mainStore.siteOptions?.seo?.metaDescription,
// 		},
// 		{
// 			name: 'keywords',
// 			content: mainStore.siteOptions?.seo?.metaKeywords
// 				? mainStore.siteOptions?.seo?.metaKeywords.join(',')
// 				: '',
// 		},
// 		{
// 			property: 'og:image',
// 			content: ogImageUrl,
// 		},
// 		{
// 			property: 'og:image:width',
// 			content: ogImageUrl ? '1200' : '',
// 		},
// 		{
// 			property: 'og:image:height',
// 			content: ogImageUrl
// 				? Math.floor(
// 						1200 /
// 							(mainStore.siteOptions?.seo?.ogImage?.metadata
// 								?.dimensions?.aspectRatio ?? 1.5)
// 				  )
// 				: '',
// 		},
// 		{
// 			property: 'og:image:type',
// 			content: mainStore.siteOptions?.seo?.ogImage?.asset?.mimeType ?? '',
// 		},
// 		{
// 			name: 'twitter:card',
// 			content: 'summary_large_image',
// 		},
// 		{
// 			name: 'twitter:title',
// 			content: mainStore.siteOptions?.name,
// 		},
// 		{
// 			name: 'twitter:description',
// 			content: mainStore.siteOptions?.seo?.metaDescription,
// 		},
// 		{
// 			name: 'twitter:image',
// 			content: ogImageUrl,
// 		},
// 	],
// })
</script>

<style scoped>
/* .CookieDeclarationType {
	margin: 40px 0 80px !important;
	padding: 20px 0 0 !important;
	border: none !important;
	border-top: 1px solid #666 !important;
}

.CookieDeclarationTableHeader,
.CookieDeclarationTableCell {
	font-weight: normal !important;
	font-size: .7em;
}

body > #CybotCookiebotDialog {
	font-family: inherit;
	width: 100%;
	top: auto;
	transform: translate(-50%, 0);
	border-radius: 0px;
	overflow: visible;
}

@media screen and (max-width: 1280px) {
	body > #CybotCookiebotDialog {
		bottom: 20px;
		width: calc(100% - 40px);
	}
}

@media screen and (min-width: 1280px) {
	body > #CybotCookiebotDialog {
		bottom: 50px;
		top: auto !important;
		transform: translate(-50%, 0) !important;
	}

	body > #CybotCookiebotDialog.CybotEdge {
		padding: 24px 24px 13px;
	}
}

body > #CybotCookiebotDialog.CybotCookiebotDialogActive + #CybotCookiebotDialogBodyUnderlay {
	opacity: .5;
}

body > #CybotCookiebotDialog div:has( + .CybotCookiebotDialogContentWrapper) {
	display: none;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogNav {
	border-bottom-color: #eee;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogNav #CybotCookiebotDialogNavList {
	padding-left: 9px;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogNav #CybotCookiebotDialogNavList .CybotCookiebotDialogNavItem {
	flex: none;
	margin: auto 10px;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogNav #CybotCookiebotDialogNavList .CybotCookiebotDialogNavItem .CybotCookiebotDialogNavItemLink {
	font-weight: 400;
	color: #808080;
	padding: 13px 0;
	border-bottom: none;
	border-top: 4px solid transparent;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogNav #CybotCookiebotDialogNavList .CybotCookiebotDialogNavItem .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
	border-top-color: #282828;
	color: #000;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogNavList {
	flex: none;
}

body > #CybotCookiebotDialog .CookieCard {
	padding-top: 1em !important;
}

body > #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
      body > #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
	font-weight: 400;
}

body > #CybotCookiebotDialog .CookieCard:before {
	content: none;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter {
	padding: 0 1em 1em !important;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle,
    body > #CybotCookiebotDialog #CybotCookiebotDialogBodyEdgeMoreDetails,
    body > #CybotCookiebotDialog #CybotCookiebotDialogPoweredByText,
    body > #CybotCookiebotDialog #CybotCookiebotDialogPoweredbyCybot,
    body > #CybotCookiebotDialog #CybotCookiebotDialogHeader {
	display: none !important;
}

body > #CybotCookiebotDialog *,
    body > #CybotCookiebotDialog #CybotCookiebotDialogBodyUnderlay * {
	font-size: 13px;
}

body > #CybotCookiebotDialog img {
	width: auto;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
	letter-spacing: 0;
	line-height: 1.5;
	padding: 0.625em 0.825em;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText,
    body > #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
	max-width: 80ch;
	margin: 0 auto 0 0;
	line-height: 1.45;
	padding-top: 0 !important;
	padding-left: 0 !important;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyButtonsWrapper {
	justify-content: center;
	flex-direction: column;
}

@media screen and (min-width: 800px) {
	body > #CybotCookiebotDialog #CybotCookiebotDialogBodyButtonsWrapper {
		flex-direction: row;
	}
}

body > #CybotCookiebotDialog .CybotCookiebotScrollContainer {
	min-height: unset;
	border-bottom: none;
	padding: 9px;
	line-height: 1.45;
}

body > #CybotCookiebotDialog .CybotCookiebotScrollContainer p:not(:last-child) {
	margin-bottom: 1.1em;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentCookieContainer button {
	min-width: auto;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentCookieContainer #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
	padding: 0 11px;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogFooter {
	padding: 18px;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
	gap: 10px;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper .CybotCookiebotDialogBodyButton {
	font-weight: 400;
	border-radius: 0;
	border-width: 1px;
	width: inherit;
	margin-right: 0;
} */
</style>